const storeTypes = {
  app:{
    user:"app_user"
  },
  user:{
    list:'user_list',
    detail:'user_detail',
    clear:'user_clear',
  },
  validation:{
    list:'validation_list',
    clear:'validation_clear',
  }
}

export default storeTypes