import { Button, Drawer, Layout, Menu, MenuProps } from "antd"
import React from "react"
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom"
import { authMiddleware } from "shared/middlewares/auth.middleware"
import { RiArticleLine, RiAttachment2, RiDashboard3Line, RiLogoutBoxRLine, RiMenLine, RiMenu2Line, RiUser3Line } from "react-icons/ri";
import { deleteAuthToken } from "shared/utils/localstorage.util";

const AdminLayout:React.FC<{params?:object}> = ({params={}}) => {

  const navigate:NavigateFunction = useNavigate()
  
  const [isOpen,setIsOpen] = React.useState(true)
  const [show,setShow] = React.useState(false)

  const handleMiddleWare = async () => {
    let res = await authMiddleware({navigate:navigate,params:params})
    if(!res) return navigate('/')
    setShow(res)
  }

  React.useEffect(()=>{
    handleMiddleWare()
  },[])

  const items: MenuProps['items'] = [
    /* {
      label: 'Tableau de bord',
      key: 'dashboard',
      icon: <RiDashboard3Line />,
      onClick: () =>{
        navigate('/admin')
      }
    }, */
    {
      label: 'Utilisateurs & validations',
      key: 'users',
      icon: <RiUser3Line />,
      onClick: () =>{
        navigate('/admin/users')
      }
    },
  ]


  const handleLogout = async () => {
    await deleteAuthToken()
    return navigate('/')
  }

  return(
    <div className={(show?'!flex':'!hidden')+" admin-layout min-h-screen flex flex-col bg-background"}>
      <Layout className="bg-white">

        <Layout.Header className="layout-header bg-white shadow border-b px-2 flex items-center">
          <div className="header-wrapper flex items-center">
            <Button className="pt-0 m-0 h-auto flex" size="small" type="link" onClick={()=>setIsOpen(!isOpen)}>
              <RiMenu2Line className="pt-0 m-0 text-primary text-2xl" />
            </Button>
            <span className="uppercase text-secondary font-semibold text-2xl">Jobito</span>
          </div>
        </Layout.Header>

        <Layout>

          <Layout.Sider breakpoint="md" collapsed={!isOpen} trigger={null} collapsible className="!bg-white border-r md:flex flex-col hidden" width={250}>

            <div className="sider-wrapper flex flex-col justify-between h-full py-10 items-center">

              <div className="sider-menu w-full">
                <Menu selectedKeys={["mail"]} className="!border-none" items={items} />
              </div>
              <div className="sider-footer">
                <Button onClick={()=>handleLogout()} className="uppercase border-red-500 font-semibold text-red-500 hover:border-red-500 hover:text-red-500 flex items-center">
                  <RiLogoutBoxRLine className="text-lg" /> {isOpen?<span className="ml-2">Déconnexion</span>:null}
                </Button>
              </div>

            </div>
            
          </Layout.Sider>

          <Layout.Content className={" m-8 bg-white rounded-lg shadow-sm p-8 overflow-x-scroll"}>
            <div className="content-wrapper w-[720px] md:w-auto">
              <Drawer footer={(
                <div className="flex justify-center">
                  <Button onClick={()=>handleLogout()} className="uppercase border-red-500 font-semibold text-red-500 hover:border-red-500 hover:text-red-500 flex items-center">
                    <RiLogoutBoxRLine className="text-lg" /> {isOpen?<span className="ml-2">Déconnexion</span>:null}
                  </Button>
                </div>
              )} open={isOpen} onClose={()=>setIsOpen(false)} placement="left" width={250} className="block md:hidden" maskClassName="block md:hidden" rootClassName="block md:hidden">
                <Menu selectedKeys={["mail"]} className="!border-none" items={items} />
              </Drawer>
              <Outlet />
            </div>
          </Layout.Content>

        </Layout>

      </Layout>
    </div>
  )
}

export default AdminLayout