import { IAction } from "shared/interfaces/action.interface"
import storeTypes from "store/type"


const initialState:any = {
  list:[],
  detail:null
}

const userReducer = (state = initialState, action:IAction) => {

  switch (action.type) {
    case storeTypes.user.list:
      return state = {
        ...state,
        list: action.payload
      }
    case storeTypes.user.detail:
      return state = {
        ...state,
        detail: action.payload
      }
      case storeTypes.user.clear:
        return state = {
          ...state,
          detail: null
        }
    default:
      return state = {...state}
  }
  
}

export default userReducer